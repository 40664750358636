import React, { useState, useEffect } from 'react';
import { useAuth } from "../iw-ui/core/auth";
import { Link } from "react-router-dom";
import Loading from "../components/Loading/Loading";

function Points(props) {
    const { authTokens, setAuthTokens } = useAuth();
    const [data, setData] = useState(null)

    useEffect(() => {
        const url = `${process.env.REACT_APP_BASE_API_URL}/v1/points`;

        const bearerToken = authTokens().access_token;

        let headers = { "Content-Type": "application/json" };
        if (bearerToken) {
            headers["Authorization"] = `Bearer ${bearerToken}`;
        }

        const request = {
            method: 'GET',
            headers: headers,
        };

        fetch(url, request)
            .then(response => {
                if (response.status === 401) {
                    setAuthTokens();
                    return;
                }
                if (response.status === 200) {
                    return response.json();
                }
            })
            .then(json => {
                setData(json);

            })
            .catch((error) => {
                console.error('Error:', error);
                //alert(error);
            }
            );

    }, [authTokens, setAuthTokens]);

    return (
        <div>
            <h2>Punkty</h2>
            <table className="table">
                <tbody>
                    <tr>
                        <th>Użytkownik</th>
                        <th>Punkty</th>
                    </tr>
                    {data ? data.map((row) => {
                        return (
                            <tr>
                                <td>
                                    <Link to={`/points/users/${row.user_id}`}>{row.user_name}</Link>
                                </td>
                                <td>{row.points}</td>
                            </tr>
                        );
                    }) : (
                        <tr>
                            <td colspan="2">
                                <Loading />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default Points;