import React, { useState, useEffect, useRef } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useAuth } from "../iw-ui/core";

function Login(props) {
    const buttonLoginRef = useRef();

    const [error, setError] = useState(null);

    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");

    const [userNameError, setuUserNameError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);


    const [isLoggedIn, setLoggedIn] = useState(false);
    const { authTokens, setAuthTokens } = useAuth();

    const [loading, setLoading] = useState(false);

    const history = useHistory();

    const referer = props.location.state ? props.location.state.referer.pathname || "/" : "/";


    useEffect(() => {
        buttonLoginRef.current.disabled = loading;
    }, [loading]);


    function postLogin() {
        setError("");
        setuUserNameError("");
        setPasswordError("");


        if (userName === ""){
            setuUserNameError("Podaj nazwę użytkownika");
            return;
        }

        if (password === ""){
            setPasswordError("Podaj hasło");
            return;
        }



        const url = `${process.env.REACT_APP_BASE_API_URL}/v1/token`;

        var params = {
            username: userName,
            password: password,
            grant_type: 'password'
        };

        var formData = new URLSearchParams();

        for (var k in params) {
            formData.append(k, params[k]);
        }


        var request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formData.toString(),
        };


        setLoading(true);

        fetch(url, request)
            .then((response) => {
                setLoading(false);
                return response.json();
            })
            .then((result) => {
                if (result) {

                    if (result.error !== undefined) {
                        setError(result.error_description);
                        setPassword("");
                        return;
                    }

                    if (result.access_token !== undefined) {
                        setAuthTokens(result);
                        setLoggedIn(true);
                        //window.location.href=referer;
                        history.push(referer);
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
                setError("Wystąpił błąd połączenia");
                console.error('Error:', error);
            }
            );



    }

    const onInputPasswordKeyDown = (event) => {
        if (event.key === 'Enter') {
            postLogin()
        }
    }

    if (authTokens()) {
        // window.location.href=referer;
        return <Redirect to={referer} />;
    }

    document.title = "SkiBet logowanie";

    return (
        <div>
            <h2>Logowanie</h2>
            <div className="row">
                <div className="col-md-8">
                    <div className="form-horizontal">
                        <h4>Proszę się zalogować</h4>
                        <hr />
                        {error && (
                            <div class="validation-summary-errors text-danger">
                                <ul>
                                    <li>{error}</li>
                                </ul>
                            </div>
                        )}
                        <div className="form-group">
                            <label className="col-md-2 control-label" for="UserName">Użytkownik</label>
                            <div className="col-md-10">
                                <input className="form-control"
                                    type="text"
                                    value={userName}
                                    onChange={e => {
                                        setUserName(e.target.value);
                                    }}
                                />
                                {userNameError && (
                                    <span className="field-validation-valid text-danger">{userNameError}</span>
                                )}
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-md-2 control-label" for="Password">Hasło</label>
                            <div className="col-md-10">
                                <input className="form-control"
                                    type="password"
                                    value={password}
                                    onChange={e => {
                                        setPassword(e.target.value);
                                    }}
                                    onKeyDown={onInputPasswordKeyDown}
                                />
                                {passwordError && (
                                    <span className="field-validation-valid text-danger">{passwordError}</span>
                                )}
                            </div>
                        </div>
                        {/* <div className="form-group">
                                <div className="col-md-offset-2 col-md-10">
                                    <div className="checkbox">
                                        <input data-val="true" data-val-required="Pole Remember me? jest wymagane."
                                            id="RememberMe" name="RememberMe" type="checkbox" value="true" /><input
                                            name="RememberMe" type="hidden" value="false" />
                                        <label for="RememberMe">Zapamiętaj mnie?</label>
                                    </div>
                                </div>
                            </div> */}
                        <div className="form-group">
                            <div className="col-md-offset-2 col-md-10">
                                <button className="btn btn-default" style={{ width: "100px" }} onClick={postLogin} ref={buttonLoginRef}>
                                    {!loading ? (
                                        <span>Zaloguj się</span>
                                    ) : (
                                        <span class="glyphicon glyphicon-refresh gly-spin" aria-hidden="true"></span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {isLoggedIn && <Redirect to={referer} />}
        </div>
    );
}

export default Login;