import React from 'react';
import { useParams } from "react-router-dom";
import BookJumperContainer from '../components/BookJumperContainer/BookJumperContainer';

function AddResult(props) {
    const { competition_id, book_type_id } = useParams();

    return (
        <BookJumperContainer typeBooksResults="results" competition_id={competition_id} book_type_id={book_type_id} />
    );

}

export default AddResult;