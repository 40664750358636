import React from 'react';
import { useParams } from "react-router-dom";
import BookJumperContainer from '../components/BookJumperContainer/BookJumperContainer';

function EditBook(props) {
    const { competition_id, id } = useParams();

    return (
        <BookJumperContainer typeBooksResults="books" competition_id={competition_id} id={id}/>
    );
}

export default EditBook;