import React from 'react';
import './Loading.css';

function Loading(props) {
    return (
        <h1 className="h-loading delayed-show">
            <span className="glyphicon glyphicon-refresh gly-spin" aria-hidden="true"></span>
        </h1>
    );
}

export default Loading;