import React, { useState, useEffect } from 'react';
import { useAuth } from "../iw-ui/core/auth";
import { Link } from "react-router-dom";
import Loading from "../components/Loading/Loading";

function Competitions(props) {

    const { authTokens, setAuthTokens } = useAuth();

    const [data, setData] = useState(null);
    const [filter, setFilter] = useState(sessionStorage.getItem("skibet_competitions_filter") ?? 1);
    
    useEffect(() => {
        const url = `${process.env.REACT_APP_BASE_API_URL}/v1/competitions?filter=${filter}`;

        const bearerToken = authTokens().access_token;

        let headers = {};
        if (bearerToken) {
            headers["Authorization"] = `Bearer ${bearerToken}`;
        }

        const request = {
            method: 'GET',
            headers: headers,
        };

        fetch(url, request)
            .then(response => {
                if (response.status === 401) {
                    setAuthTokens();
                    return;
                }
                if (response.status === 200) {
                    return response.json();
                }
            })
            .then(json => {
                setData(json);

            })
            .catch((error) => {
                console.error('Error:', error);
                //alert(error);
            }
            );

    }, [authTokens,setAuthTokens, filter]);

    const onSelectChange = (e) => {
        setFilter(e.target.value);
        sessionStorage.setItem("skibet_competitions_filter", e.target.value);
    };


    return (
        <div>
            <h2>Konkursy</h2>
            <div>
                <select className="form-control valid" value={filter} onChange={onSelectChange}>
                    <option value="1">Dzisiejsze</option>
                    <option value="2">Przyszłe</option>
                    <option value="3">Archiwalne</option>
                    <option value="4">Wszystkie</option>
                </select>
            </div>
            <br />
            <table className="table">
                <tbody>
                    <tr>
                        <th>Start</th>
                        <th>Nazwa</th>
                        <th>Lokalizacja</th>
                        <th>Rozmiar skoczni</th>
                        <th></th>
                    </tr>

                    {data ? data.map((row) => {
                        return (
                            // <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                            <tr>
                                <td>{row.start_at.replace("T", " ").substring(0, 16)}</td>
                                <td>{row.discipline_name}</td>
                                <td>{row.venue_name} ({row.venue_country_code})</td>
                                <td>{row.hillsize}</td>
                                <td>
                                    <Link to={`/competitions/${row.id}/books`}>Typy</Link> | <Link to={`/competitions/${row.id}/results`}>Wyniki</Link>
                                </td>
                            </tr>
                        );
                    }) : (
                        <tr>
                            <td colspan="5">
                                <Loading />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default Competitions;