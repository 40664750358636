import React from 'react';

function Help(props) {
    return (
        <div>
            <h2>Pomoc</h2>
            <h3>Zasady typowania</h3>

            <div>
                <p>Należy obstawić po pięciu zawodników w każdej kategorii, tj. top 5 najlepszych skoczków konkursu oraz top 5 Polaków.</p>
                <p>Punktacja jest następująca:</p>
                <ul>
                    <li>3 pkt za celne obstawienie skoczka na danym miejscu</li>
                    <li>2 pkt, jeśli miejsce zajęte przez zawodnika różni się o jedną pozycję w stosunku do obstawionego</li>
                    <li>1 pkt w przypadku, gdy zawodnik rzeczywiście pojawi się wśród najlepszej piątki, lecz na innym miejscu niż przewidziane</li>
                </ul>
            </div>
        </div>
    );
}

export default Help;