import './App.css';
import './gly-spin.css';
import './delayed-show.css';
import React, { useState, useEffect } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


//import configData from "./config.json";

import { PrivateRoute, AuthContext, ScrollMemory } from './iw-ui/core';

import Login from './pages/Login';
import Home from './pages/Home';
import Competitions from './pages/Competitions';
import Results from './pages/Results';
import Books from './pages/Books';
import EditResult from './pages/EditResult';
import AddResult from './pages/AddResult';
import AddBook from './pages/AddBook';
import EditBook from './pages/EditBook';
import PointsByUser from './pages/PointsByUser';
import Points from './pages/Points';
import Help from './pages/Help';
import ChangePassword from './pages/ChangePassword';
import Register from './pages/Register';
import CookieBanner from './components/CookieBanner/CookieBanner'

function App() {

  const localStorageTokenKey = "skibet_tokens"

  const [authTokens, setAuthTokens] = useState(JSON.parse(localStorage.getItem(localStorageTokenKey)));

  const [navbarVisible, setNavbarVisible] = useState(false);

  const tokens = () => {
    const data = JSON.parse(localStorage.getItem(localStorageTokenKey));
    //setAuthTokens(data);
    return data;
  }

  const setTokens = (data) => {

    if (data !== undefined)
      localStorage.setItem(localStorageTokenKey, JSON.stringify(data));
    else
      localStorage.removeItem(localStorageTokenKey);
    setAuthTokens(data);
  }

  const onLogout = () => {
    setTokens();
  };

  const onNavbarToggleClick = () => {
    setNavbarVisible(!navbarVisible);
  };

  const handleClickOutside = e => {
    if (navbarVisible) {
      setTimeout(() => {
        setNavbarVisible(false);
      }, 150);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  });

  return (
    <AuthContext.Provider value={{ authTokens: tokens, setAuthTokens: setTokens }}>
      <Router>

        <div className="navbar navbar-inverse navbar-fixed-top">
          <ScrollMemory />
          <div className="container">
            <div className="navbar-header">
              <button type="button" className="navbar-toggle" onClick={onNavbarToggleClick}>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <Link to="/" className="navbar-brand">SkiBet</Link>
            </div>
            <div className={`navbar-collapse collapse ${navbarVisible ? "in" : ""}`}>
              {authTokens && (
                <ul className="nav navbar-nav">
                  <li><Link to="/competitions">Konkursy</Link></li>
                  <li><Link to="/points">Punkty</Link></li>
                  <li><Link to="/help">Pomoc</Link></li>
                </ul>
              )}
              <ul className="nav navbar-nav navbar-right">
                {authTokens ? (
                  <>
                    <li><Link to="/change_password" title="Zmiana hasła">Witaj {authTokens.username}!</Link></li>
                    <li><Link to="/" onClick={onLogout}>Wyloguj</Link></li>
                  </>
                ) : (
                  <li><Link to="/login">Zaloguj się</Link></li>
                )}
              </ul>

            </div>
          </div>
        </div>
        <div className="container body-content">
          <Switch>
            <Route path="/login" component={Login} title="Login" />
            <PrivateRoute path="/competitions/:competition_id/results/book_type/:book_type_id/add" component={AddResult} title="Dodawanie wyniku" />
            <PrivateRoute path="/competitions/:competition_id/results/edit/:id" component={EditResult} title="Edycja wyniku" />
            <PrivateRoute path="/competitions/:competition_id/books/book_type/:book_type_id/add" component={AddBook} title="Dodawanie typu" />
            <PrivateRoute path="/competitions/:competition_id/books/edit/:id" component={EditBook} title="Edycja typu" />
            <PrivateRoute path="/competitions/:competition_id/results" component={Results} title="Wyniki" />
            <PrivateRoute path="/competitions/:competition_id/books" component={Books} title="Typy" />
            <PrivateRoute path="/competitions" component={Competitions} title="Konkursy" />
            <PrivateRoute path="/points/users/:user_id" component={PointsByUser} title="Punkty użytkownika" />
            <PrivateRoute path="/points" component={Points} title="Punkty" />
            <PrivateRoute path="/help" component={Help} title="Pomoc" />
            <PrivateRoute path="/change_password" component={ChangePassword} title="Zmiana hasła" />
            <PrivateRoute path="/register" component={Register} title="Rejestracja" />
            <Route path="/" component={Home} title="Home" />
          </Switch>
          <hr />
          <footer>
            <p>&copy; 2021-2022 - SoccerBet</p>
          </footer>
        </div>
        <CookieBanner appId={localStorageTokenKey} />
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
