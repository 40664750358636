import React from 'react';
import { useParams } from "react-router-dom";
import BooksContainer from '../components/BooksContainer/BooksContainer';

function Results(props) {
    const { competition_id } = useParams()


    return (
       <BooksContainer  typeBooksResults="results" competition_id={competition_id} title="Wyniki" />
    );

}

export default Results;