import React from 'react';

Home.propTypes = {

};

function Home(props) {

    document.title = "SkiBet";

    return (
        <div className="jumbotron">
            <h1>SkiBet</h1>
            <p className="lead">Witamy !!!</p>
        </div>
    );
}

export default Home;