import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAuth } from "../../iw-ui/core/auth";
import Select
// , { components, GroupProps } 
from 'react-select';



import './BookJumperContainer.css';

function BookJumperContainer({ typeBooksResults, id, competition_id, book_type_id }) {

    const history = useHistory();

    const isAdd = id == null

    const { authTokens, setAuthTokens } = useAuth();
    const [data, setData] = useState(null);
    const [jumperId, setJumperId] = useState(null);
    const [jumperName, setJumperName] = useState(null);
    const [place, setPlace] = useState(null);

    const [message, setMessage] = useState();
    const [jumperError, setJumperError] = useState();
    const [placeError, setPlaceError] = useState();

    useEffect(() => {
        const url = isAdd ?
            `${process.env.REACT_APP_BASE_API_URL}/v1/competitions/${competition_id}/${typeBooksResults}/0?book_type_id=${book_type_id}`
            :
            `${process.env.REACT_APP_BASE_API_URL}/v1/competitions/${competition_id}/${typeBooksResults}/${id}`;

        const bearerToken = authTokens().access_token;

        let headers = { "Content-Type": "application/json" };
        if (bearerToken) {
            headers["Authorization"] = `Bearer ${bearerToken}`;
        }

        const request = {
            method: 'GET',
            headers: headers,
        };

        fetch(url, request)
            .then((response) => {
                if (response.status === 401) {
                    setAuthTokens();
                    return;
                }
                if (response.status === 200) {
                    return response.json();
                }
                if (response.status === 422) {
                    return;
                }
            })
            .then(json => {
                setJumperId(json.jumper_id)
                setJumperName(json.jumper_name)
                setPlace(json.place);
                setData(json);
            })
            .catch((error) => {
                console.error('Error:', error);
                //alert(error);
            }
            );

    }, [authTokens, setAuthTokens, isAdd, typeBooksResults, competition_id, book_type_id, id]);


    const onButtonCancelClick = () => {
        history.goBack();
    };

    const onSubmitClick = () => {
        var data = {
            jumper_id: jumperId,
            place: parseInt(place),
            book_type_id: parseInt(book_type_id),
        };

        const url = isAdd ?
            `${process.env.REACT_APP_BASE_API_URL}/v1/competitions/${competition_id}/${typeBooksResults}`
            :
            `${process.env.REACT_APP_BASE_API_URL}/v1/competitions/${competition_id}/${typeBooksResults}/${id}`;

        const method = isAdd ? 'POST' : 'PUT';
        const bearerToken = authTokens().access_token;
        let headers = { "Content-Type": "application/json" };
        if (bearerToken) {
            headers["Authorization"] = `Bearer ${bearerToken}`;
        }

        fetch(
            url, {
            method: method,
            headers: headers,
            body: JSON.stringify(data)
        }
        )
            .then((response) => {
                if (response.status === 401) {
                    setAuthTokens();
                    return;
                }
                if (response.status === 200) {
                    onButtonCancelClick();
                    return;
                }

                if (response.status === 422) {
                    return response.json();
                }
            })
            .then((result) => {
                if (result) {
                    
                    setMessage();
                    setJumperError();
                    setPlaceError();
                    for (var key in result) {
                        if (key === "Message") {
                            setMessage(result[key]);
                        }
                        if (key === "message") {
                            setMessage(result[key]);
                        }
                        if (key === "jumper") {
                            setJumperError(result[key]);
                        }
                        if (key === "place") {
                            setPlaceError(result[key]);
                        }
                    }
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            }
            );

    };


    return (
        <>
            {data && (
                <div>


                    <h2>{data.competition.start_at.replace("T", " ").substring(0, 16)} {data.competition.location}({data.competition.country})</h2>
                    {/* <h4>{data.competition.name}</h4> */}



                    <div className="form-horizontal">
                        <h4>{isAdd ? "Dodanie do" : "Edycja w"} {data.book_type.name}</h4>
                        <hr />
                        {message && (
                            <div class="validation-summary-errors text-danger">{message}</div>
                        )}
                        <div className="form-group">
                            <label className="control-label col-md-2" for="jumper_id">Zawodnik</label>
                            {/* <div className="col-md-10">
                                <select className="form-control" value={jumperId} onChange={(e) => { setJumperId(e.target.value) }}>
                                    {data.jumpers.map((jumper) => {
                                        return (
                                            // <option value={jumper.id} selected={jumper.id===data.jumper_id}>{jumper.name}</option>
                                            <option value={jumper.id}>{jumper.name}</option>
                                        );
                                    })}
                                </select>
                            </div> */}
                            <div className="col-md-10">
                                <Select
                                    className="select-jumper"
                                    options={data.jumpers.map(({ id, name, is_disabled }) => ({ value: id, label: name, isDisabled: is_disabled }))}
                                    value={{ value: jumperId, label: jumperName }}
                                    //onChange={(e) => { setJumperId(e.target.value) }}
                                    onChange={(e) => {
                                        setJumperId(e.value);
                                        setJumperName(e.label);
                                    }}
                                />
                                {jumperError && (
                                    <span className="field-validation-valid text-danger">{jumperError}</span>
                                )}
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label col-md-2" for="place">Miejsce</label>
                            <div className="col-md-10">
                                <input className="form-control text-box single-line" type="number" value={place} onChange={(e) => { setPlace(e.target.value) }} />
                                {placeError && (
                                    <span className="field-validation-valid text-danger">{placeError}</span>
                                )}
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="col-md-offset-2 col-md-10">
                                <input type="submit" value={isAdd ? "Dodaj" : "Zapisz"} className="btn btn-default" onClick={onSubmitClick} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default BookJumperContainer;