import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";
import { useAuth } from "../../iw-ui/core/auth";
import Loading from "../Loading/Loading";

import './BooksContainer.css';

function BooksContainer({ typeBooksResults, competition_id, title }) {



    const { authTokens, setAuthTokens } = useAuth();
    const [data, setData] = useState(null);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        const url = `${process.env.REACT_APP_BASE_API_URL}/v1/competitions/${competition_id}/${typeBooksResults}`;

        const bearerToken = authTokens().access_token;

        let headers = { "Content-Type": "application/json" };
        if (bearerToken) {
            headers["Authorization"] = `Bearer ${bearerToken}`;
        }

        const request = {
            method: 'GET',
            headers: headers,
        };

        fetch(url, request)
            .then((response) => {
                if (response.status === 401) {
                    setAuthTokens();
                    return;
                }
                if (response.status === 200) {
                    return response.json();
                }
                if (response.status === 422) {
                    return;
                }
            })
            .then(json => {
                setData(json);

            })
            .catch((error) => {
                console.error('Error:', error);
                //alert(error);
            }
            );

    }, [refresh, authTokens, setAuthTokens, competition_id, typeBooksResults]);


    const onScrollToUserClick = (e) => {
        const user_id = e.currentTarget.dataset.user_id;
        const className = `span-user-book-${user_id}`;

        scroller.scrollTo(className, {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
            offset: -70
        });
    };



    const onCalculateClick = (e) => {
        e.preventDefault();

        const bearerToken = authTokens().access_token;
        let headers = { "Content-Type": "application/json" };
        if (bearerToken) {
            headers["Authorization"] = `Bearer ${bearerToken}`;
        }
        const url = `${process.env.REACT_APP_BASE_API_URL}/v1/competitions/${data.competition.id}/calculate`;
        const method = 'GET';
        fetch(
            url, {
            method: method,
            headers: headers,
            // body: JSON.stringify(data)
        }
        )
            .then((response) => {
                if (response.status === 401) {
                    setAuthTokens();
                    return;
                }
                setRefresh(!refresh);
                //response.json()
            })
            // .then((result) => {
            // })
            .catch((error) => {
                console.error('Error:', error);
            }
            );
    };

    const onBookCopyClick = (e) => {
        e.preventDefault();

        const bearerToken = authTokens().access_token;
        let headers = { "Content-Type": "application/json" };
        if (bearerToken) {
            headers["Authorization"] = `Bearer ${bearerToken}`;
        }
        const url = `${process.env.REACT_APP_BASE_API_URL}/v1/competitions/${data.competition.id}/book_copy`;
        const method = 'GET';
        fetch(
            url, {
            method: method,
            headers: headers,
            // body: JSON.stringify(data)
        }
        )
            .then((response) => {
                if (response.status === 401) {
                    setAuthTokens();
                    return;
                }
                setRefresh(!refresh);
                return response.json();
            })
            .then((result) => {
                if (result && result.message){
                    alert(result.message)
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            }
            );
    };

    const onMoveClick = (id, shift) => {
        const bearerToken = authTokens().access_token;
        let headers = { "Content-Type": "application/json" };
        if (bearerToken) {
            headers["Authorization"] = `Bearer ${bearerToken}`;
        }
        const url = `${process.env.REACT_APP_BASE_API_URL}/v1/competitions/${data.competition.id}/${typeBooksResults}/${id}/move`;
        const method = 'POST';

        const postData = {
            shift: shift
        };

        fetch(
            url, {
            method: method,
            headers: headers,
            body: JSON.stringify(postData)
        }
        )
            .then((response) => {
                if (response.status === 401) {
                    setAuthTokens();
                    return;
                }
                setRefresh(!refresh);
                //response.json()
            })
            // .then((result) => {
            // })
            .catch((error) => {
                console.error('Error:', error);
            }
            );
    };

    return (
        <>
            {data ? (
                <div>
                    <h2>{title} {data.competition.start_at.replace("T", " ").substring(0, 16)} {data.competition.location}({data.competition.country})</h2>
                    <h4>{data.competition.name}</h4>
                    {data.show_calculate && (
                        <p>
                            <a href="/" onClick={onCalculateClick}>Przelicz</a>
                        </p>
                    )}
                    {data.show_book_copy && (
                        <p>
                            <a href="/" onClick={onBookCopyClick}>Kopiuj typy</a>
                        </p>
                    )}

                    {data.summary && (
                        <table className="table" style={{ maxWidth: "350px" }}>
                            <tbody>
                                <tr>
                                    <th>
                                        Użytkownik
                                    </th>
                                    <th style={{ width: "50px", textAlign: "right" }}>
                                        Punkty
                                    </th>
                                    <th style={{ width: "50px" }}></th>
                                </tr>
                                {data.summary.map((summary) => {
                                    return (
                                        <tr>
                                            <td>
                                                {summary.user_name}
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                                {summary.points}
                                            </td>
                                            <td>
                                                <button className="btn btn-xs" data-user_id={summary.user_id} onClick={onScrollToUserClick}>
                                                    <span className="glyphicon glyphicon-chevron-down" aria-hidden="true"></span>
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    )}
                    {data.book_types.map((book_type) => {
                        return (
                            <>
                                <hr />
                                <h3>{data.show_user && (<span className={`span-user-book-${book_type.user.id}`}> {book_type.user.name} - </span>)}{book_type.name}</h3>
                                {data.is_editable && (
                                    <p>
                                        <Link to={`/competitions/${data.competition.id}/${typeBooksResults}/book_type/${book_type.id}/add`}>Dodaj</Link> ({book_type.max_place})
                                    </p>
                                )}
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <th>
                                                Zawodnik
                                            </th>
                                            <th>
                                                Miejsce
                                            </th>
                                            {data.show_points && (
                                                <th>Punkty</th>
                                            )}
                                            <th></th>
                                        </tr>
                                        {book_type.items.map((item) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        {item.jumper.first_name} {item.jumper.last_name} {book_type.show_country && `(${item.jumper.country})`}
                                                    </td>
                                                    <td>
                                                        {item.place}
                                                    </td>
                                                    {data.show_points && (
                                                        <td>{item.points}</td>
                                                    )}
                                                    <td>
                                                        {data.is_editable && (
                                                            <span>
                                                                {data.show_move &&(
                                                                <span>
                                                                    <div className={`btn btn-xs${(item.place === null || item.place === 1) ? " disabled btn-disabled" : ""}`} onClick={() => { onMoveClick(item.id, -1) }}>
                                                                        <span className="glyphicon glyphicon-chevron-up" aria-hidden="true"></span>
                                                                    </div>
                                                                    <div className={`btn btn-xs${(item.place === null || item.place === book_type.max_place) ? " disabled btn-disabled" : ""}`} onClick={() => { onMoveClick(item.id, 1) }}>
                                                                        <span className="glyphicon glyphicon-chevron-down" aria-hidden="true"></span>
                                                                    </div>
                                                                </span>
                                                                )}
                                                                <Link to={`/competitions/${data.competition.id}/${typeBooksResults}/edit/${item.id}`}>Edytuj</Link>
                                                            </span>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </>
                        );
                    })}
                </div>
            ) : (
                <Loading />
            )
            }
        </>
    );
}

export default BooksContainer;