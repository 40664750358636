import React, { useState, useEffect } from 'react';
import { useAuth } from "../iw-ui/core/auth";
import { useParams } from "react-router-dom";

function PointsByUser(props) {
    const { user_id } = useParams()
    const { authTokens, setAuthTokens } = useAuth();
    const [data, setData] = useState(null)

    useEffect(() => {
        const url = `${process.env.REACT_APP_BASE_API_URL}/v1/points/users/${user_id}`;

        const bearerToken = authTokens().access_token;

        let headers = { "Content-Type": "application/json" };
        if (bearerToken) {
            headers["Authorization"] = `Bearer ${bearerToken}`;
        }

        const request = {
            method: 'GET',
            headers: headers,
        };

        fetch(url, request)
            .then(response => {
                if (response.status === 401) {
                    setAuthTokens();
                    return;
                }
                if (response.status === 200) {
                    return response.json();
                }
            })
            .then(json => {
                setData(json);

            })
            .catch((error) => {
                console.error('Error:', error);
                //alert(error);
            }
            );

    }, [authTokens, setAuthTokens, user_id]);

    return (
        <>
            {data && (
                <div>
                    <h2>Punkty użytkownika {data && data.user.name}</h2>

                    <table className="table">
                        <tbody>
                            <tr>
                                <th>Start</th>
                                <th>Nazwa</th>
                                <th>Lokalizacja</th>
                                <th>Punkty</th>
                            </tr>
                            {data && data.points.map((row) => {
                                return (
                                    <tr>
                                        <td>{row.start_at.replace("T", " ").substring(0, 16)}</td>
                                        <td>{row.competition_name}</td>
                                        <td>{row.location}</td>
                                        <td>{row.points}</td>
                                    </tr>

                                );
                            })}

                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
}

export default PointsByUser;