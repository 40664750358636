import React, { useEffect, useState } from 'react';
import './CookieBanner.css';

function CookieBanner({ appId }) {

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (!localStorage.getItem(appId + "-cookie-accepted"))
            setVisible(true);
    }, [appId]);
    const onSubmitClick = () => {
        localStorage.setItem(appId + "-cookie-accepted", "true");
        setVisible(false);
    };
    return (
        <>{visible && (
            <div className='cookies-banner'>
                <div className='cookies-banner-container'>
                    <p className='cookies-banner-info'>Strona korzysta z plików cookies w celu realizacji usług. Możesz określić warunki przechowywania lub dostępu do plików cookies w Twojej przeglądarce.</p>
                    <div className='cookies-banner-close'>
                        <input type="submit" value="Akceptuję" className="btn btn-default" onClick={onSubmitClick} />
                    </div>
                </div>
            </div >
        )}</>
    )
}

export default CookieBanner