import React from 'react';
import { useParams } from "react-router-dom";
import BooksContainer from '../components/BooksContainer/BooksContainer';

function Books(props) {
    const { competition_id } = useParams()


    return (
       <BooksContainer  typeBooksResults="books" competition_id={competition_id} title="Typy" />
    );
}

export default Books;