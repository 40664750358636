import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../iw-ui/core/auth";

function Register(props) {
    const history = useHistory();
    const { authTokens, setAuthTokens } = useAuth();

    const buttonSubbmitRef = useRef();

    const [error, setError] = useState();
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [loading, setLoading] = useState(false);


    useEffect(() => {
        buttonSubbmitRef.current.disabled = loading;
    }, [loading]);


    const onButtonCancelClick = () => {
        history.push("/")
    };


    const onSubmitClick = () => {

        if (userName === "") {
            setError("Pole UserName jest wymagane");
            return;
        }

        if (email === "") {
            setError("Pole Email jest wymagane");
            return;
        }

        if (password === "") {
            setError("Pole Hasło jest wymagane");
            return;
        }



        if (password !== confirmPassword) {
            setError("Hasło i powtórzone hasło nie pasują do siebie");
            return;
        }


        const url = `${process.env.REACT_APP_BASE_API_URL}/v1/account/register`;
        const method = 'POST';
        const bearerToken = authTokens().access_token;

        let headers = { "Content-Type": "application/json" };
        if (bearerToken) {
            headers["Authorization"] = `Bearer ${bearerToken}`;
        }

        var data = {
            username: userName,
            email: email,
            password: password,
            confirm_password: confirmPassword,
        };

        setLoading(true);

        fetch(
            url, {
            method: method,
            headers: headers,
            body: JSON.stringify(data)
        }
        )
            .then((response) => {
                setLoading(false);
                if (response.status === 401) {
                    setAuthTokens();
                    return;
                }
                if (response.status === 200) {
                    onButtonCancelClick();
                    return;
                }

                if (response.status === 422) {
                    return response.json();
                }
            })
            .then((result) => {
                if (result) {
                    setError(result.message);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error('Error:', error);
            }
            );

    }

    return (
        <div>

            <h2>Rejestracja</h2>

            <div className="form-horizontal">
                <h4>Utwórz nowe konto</h4>
                <hr />
                {error && (
                    <div className="validation-summary-valid text-danger">
                        <ul>
                            <li>
                                {error}
                            </li>
                        </ul>
                    </div>
                )}
                <div className="form-group">
                    <label className="col-md-2 control-label" for="UserName">Nazwa użytkownika</label>
                    <div className="col-md-10">
                        <input
                            className="form-control"
                            type="text"
                            value={userName}
                            onChange={e => {
                                setUserName(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-md-2 control-label" for="Email">Email</label>
                    <div className="col-md-10">
                        <input
                            className="form-control"
                            type="text"
                            value={email}
                            onChange={e => {
                                setEmail(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-md-2 control-label" for="Password">Hasło</label>
                    <div className="col-md-10">
                        <input
                            className="form-control"
                            type="password"
                            value={password}
                            onChange={e => {
                                setPassword(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-md-2 control-label" for="ConfirmPassword">Powtóz hasło</label>
                    <div className="col-md-10">
                        <input
                            className="form-control"
                            type="password"
                            value={confirmPassword}
                            onChange={e => {
                                setConfirmPassword(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-md-offset-2 col-md-10">
                        <button className="btn btn-default" style={{ width: "100px" }} onClick={onSubmitClick} ref={buttonSubbmitRef}>
                            {!loading ? (
                                <span>Zarejestruj</span>
                            ) : (
                                <span class="glyphicon glyphicon-refresh gly-spin" aria-hidden="true"></span>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;